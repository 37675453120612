/* Initial State Imports */

import InitialState from "./InitialState";

const GlobalReducer = (state = InitialState, action) => {
  switch (action.type) {
    case "fetchLogAccessCodesStart":
      return {
        ...state,
        logAccessCodes: { ...state.logAccessCodes, isLoading: true },
      };
    case "fetchLogAccessCodesSuccess":
      return {
        ...state,
        logAccessCodes: {
          ...state.logAccessCodes,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchLogAccessCodesError":
      return {
        ...state,
        logAccessCodes: {
          ...state.logAccessCodes,
          isLoading: false,
          error: action.payload,
        },
      };
    case "fetchLeadsStart":
      return {
        ...state,
        leads: { ...state.leads, isLoading: true },
      };
    case "fetchLeadsSuccess":
      return {
        ...state,
        leads: {
          ...state.leads,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchLeadsError":
      return {
        ...state,
        leads: {
          ...state.leads,
          isLoading: false,
          error: action.payload,
        },
      };
    case "fetchServiceLeadsStart":
      return {
        ...state,
        serviceLeads: { ...state.serviceLeads, isLoading: true },
      };
    case "fetchServiceLeadsSuccess":
      return {
        ...state,
        serviceLeads: {
          ...state.serviceLeads,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchServiceLeadsError":
      return {
        ...state,
        serviceLeads: {
          ...state.serviceLeads,
          isLoading: false,
          error: action.payload,
        },
      };
    case "fetchRequirementLeadsStart":
      return {
        ...state,
        requirementLeads: { ...state.requirementLeads, isLoading: true },
      };
    case "fetchRequirementLeadsSuccess":
      return {
        ...state,
        requirementLeads: {
          ...state.requirementLeads,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchRequirementLeadsError":
      return {
        ...state,
        requirementLeads: {
          ...state.requirementLeads,
          isLoading: false,
          error: action.payload,
        },
      };
    case "fetchSinglePropertyStart":
      return {
        ...state,
        singleProperty: { ...state.singleProperty, isLoading: true },
      };
    case "fetchSinglePropertySuccess":
      return {
        ...state,
        singleProperty: {
          ...state.singleProperty,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchSinglePropertyError":
      return {
        ...state,
        singleProperty: {
          ...state.singleProperty,
          isLoading: false,
          error: action.payload,
        },
      };
    case "fetchSinglePropertyStartDM":
      return {
        ...state,
        singleProperty: { ...state.singleProperty, isLoading: true },
      };
    case "fetchSinglePropertySuccessDM":
      return {
        ...state,
        singleProperty: {
          ...state.singleProperty,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchSinglePropertyErrorDM":
      return {
        ...state,
        singleProperty: {
          ...state.singleProperty,
          isLoading: false,
          error: action.payload,
        },
      };
    case "fetchSingleBlogStart":
      return {
        ...state,
        singleBlog: { ...state.singleBlog, isLoading: true },
      };
    case "fetchSingleBlogSuccess":
      return {
        ...state,
        singleBlog: {
          ...state.singleBlog,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchSingleBlogError":
      return {
        ...state,
        singleBlog: {
          ...state.singleBlog,
          isLoading: false,
          error: action.payload,
        },
      };
    case "fetchSingleStreamStart":
      return {
        ...state,
        singleStream: { ...state.singleStream, isLoading: true },
      };
    case "fetchSingleStreamSuccess":
      return {
        ...state,
        singleStream: {
          ...state.singleStream,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchSingleStreamError":
      return {
        ...state,
        singleStream: {
          ...state.singleStream,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default GlobalReducer;
